import * as React from 'react';

import CoverHeading from '../components/cover/CoverHeading';
import ContactWrapper from '../components/cta/ContactWrapper';
import Insights from '../components/cta/Insights';
import AppContainer from '../components/Layout/AppContainer';
import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section';
import AiSolutions from '../content/containers/AiSolutions';
import CloudSolutions from '../content/containers/CloudSolutions';
import Impact from '../content/containers/Impact';
import AboutBlock from '../static/pages/AboutBlock';

class Solutions extends React.Component {
    public CoverContent() {
        const { id, slug, header } = AboutBlock;

        return (
            <CoverHeading id={id} slug={slug} header={header} containers={[]} />
        );
    }

    public render() {
        return (
            <AppContainer>
                <Layout cover={<this.CoverContent />}>
                    <Section>
                        <AiSolutions />
                        <CloudSolutions />
                    </Section>
                    <Section>
                        <Impact />
                        <ContactWrapper />
                    </Section>
                </Layout>
            </AppContainer>
        );
    }
}

export default Solutions;
